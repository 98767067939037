<template>
  <!-- v-if needs to be here, bc filters and column settings depend on the landingpage types  -->
  <div
    v-if="landingpages"
    class="h-full full-size-container"
  >
    <LeadList
      :leads="preparedLeads"
      :leads-by-status="leadsByStatus"
      :users="usersForSelect()"
      :commission="commission"
      :loading="$apollo.queries.leads.loading"
      :leads-by-status-loading="leadsByStatusLoading"
      :leads-pipeline-statuses="leadsStatuses"
      :server-items-length="leads.total"
      :items-per-page="itemsPerPage"
      :refetch-leads="refetchLeads"
      :disable-filters="isOnlyAssignedToMe"
      :disable-lead-import="!canImportLeads"
      :landingpage-types="landingpages"
      :select-items="selectLead"
      :is-lead-import-table="canImportLeads"
      :selected-lead-ids="selectedLeadIds"
      :parent-trigger-select-all-items="triggerSelectAllItems"
      :trigger-reset-delete-leads="triggerResetDeleteLeads"
      :trigger-reset-assign-leads="triggerResetAssignLeads"
      :select-all-leads-state="selectAllLeadsState"
      :is-deleting-leads="isDeletingLeads"
      :is-assigning-leads="isAssigningLeads"
      :current-status-filter="filters.currentStatus"
      :sort-leads-by-position="sortLeadsByPosition"
      @allItemsSelected="allItemsSelected"
      @updateFetchIdsParameters="updateFetchIdsParameters"
      @invitationSent="invitationSent"
      @deleteLeads="deleteLeads"
      @assignLeads="assignLeads"
      @updateLeadsPosition="updateLeadsPosition"
      @getLeadsByStatus="getLeadsByStatus"
      @statusDeleted="statusDeleted"
      @triggeredSelectAllItems="triggeredSelectAllItems"
      @triggeredResetDeleteLeads="triggeredResetDeleteLeads"
      @triggeredResetAssignLeads="triggeredResetAssignLeads"
    />
  </div>
</template>

<script>
import GET_LEADS_PIPELINE_STATUSES from './LeadList/Cards/queries/GetLeadsPipelineStatuses.gql'
import LEADS from './queries/Leads.gql'
import ASSIGN_LEADS from './queries/AssignLeads.gql'
import DELETE_LEADS from '@/modules/leads/queries/DeleteLeads.gql'
import LANDINGPAGES from './Landingpages.gql'
import ACQUISITION_BOOSTER_LEAD_IDS from './queries/AcquisitionBoosterLeadIds.gql'
import LEAD_IDS from './queries/LeadIds.gql'
import bus, { eventNames } from '@/lib/eventBus'
import { InvitationContactFilter } from './enums/Invitation'
import featureMixin from '@/mixins/feature'
import { DeleteLeadsContactFilter } from './enums/DeleteLeads'
import UPDATE_LEADS_POSITION from '@/modules/leads/Lead/queries/UpdateLeadsPosition.gql'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import { Status } from '@/modules/leads/enums/Status'
import leadStatus from '@/lib/leadStatus'
import USERS from '@/modules/leads/queries/Users.gql'
import { sortByString } from '@/lib/sort-functions'

export default {
  components: {
    LeadList: () => import('./LeadList')
  },
  mixins: [featureMixin],
  props: {
    isOnlyAssignedToMe: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      leadsByStatusLoading: true,
      leadsByStatus: [],
      leads: {
        total: 0,
        entries: []
      },
      sortBy: 'lastConversionAt',
      sortDesc: true,
      page: 1,
      itemsPerPage: 20,
      search: '',
      filters: {
        currentStatus: null,
        assignedUser: null,
        valuations: false,
        returnCalls: false,
        lifeAnnuities: false,
        partialSales: false,
        leadOriginConversion: true,
        leadOriginImport: true
      },
      landingpageSlugFilter: '',

      selectAllLeadsState: 'idle',
      selectedLeadIds: [],

      isDeletingLeads: false,
      isAssigningLeads: false,

      company: {},

      triggerSelectAllItems: false,
      triggerResetAssignLeads: false,
      triggerResetDeleteLeads: false
    }
  },
  computed: {
    landingpages () {
      if (!this.company) return
      return this.company.landingpages
    },
    commission () {
      return this.company.commission
    },
    preparedLeads () {
      if (!this.leads) return
      return this.leads.entries
    },

    leadsStatuses () {
      if (!this.leadsPipelineStatuses) return
      return this.leadsPipelineStatuses
    },

    canImportLeads () {
      return this.isFeatureActive(this.featureNames.LEAD_IMPORT)
    }
  },
  apollo: {
    users: {
      query: USERS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    leadsPipelineStatuses: {
      query: GET_LEADS_PIPELINE_STATUSES,
      update (data) {
        if (!data?.leadsPipelineStatuses) {
          return []
        }

        const statuses = data.leadsPipelineStatuses
        const statusCreated = statuses.find(status => status.name === Status.CREATED)
        const reorderedStatuses = statuses
          .filter(status => status.name !== Status.CREATED)
          .map((status, index, array) => ({
            ...status,
            isFirst: index === 0,
            isLast: index === array.length - 1
          }))

        return statusCreated
          ? [...reorderedStatuses, { ...statusCreated, isFirst: false, isLast: false }]
          : reorderedStatuses
      }
    },
    leads: {
      query: LEADS,
      variables () {
        return {
          landingpageSlug: this.landingpageSlugFilter,
          input: {
            companyId: this.$auth.user.companyId,
            onlyAssignedToMe: this.isOnlyAssignedToMe,
            sortBy: this.sortBy,
            view: 'all',
            sortDesc: this.sortDesc,
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            search: this.search,
            filters: Object.assign({}, this.filters)
          }
        }
      },
      result () {
        this.triggerSelectAllItems = false
      }
    },
    company: {
      query: LANDINGPAGES,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },
  methods: {
    triggeredResetDeleteLeads () {
      this.triggerResetDeleteLeads = false
    },
    triggeredResetAssignLeads () {
      this.triggerResetAssignLeads = false
    },
    triggeredSelectAllItems () {
      this.triggerSelectAllItems = false
    },
    async refetchLeads ({ sortBy = this.sortBy, sortDesc = this.sortDesc, page = this.page, itemsPerPage = this.itemsPerPage, search = this.search, filters = this.filters, landingpageSlugFilter = this.landingpageSlugFilter, view }) {
      // cancel pending call
      clearTimeout(this._timerId)

      this.page = page
      this.sortBy = sortBy
      this.sortDesc = sortDesc
      this.itemsPerPage = itemsPerPage
      this.filters = filters
      this.landingpageSlugFilter = landingpageSlugFilter

      // delay new call 1000ms
      this._timerId = setTimeout(async () => {
        if (view === 'list') {
          this.$apollo.queries.leads.refetch()
          return
        }
        this.search = search
        if (this.filters.currentStatus) {
          const currentStatus = this.leadsPipelineStatuses.filter(status => this.filters.currentStatus.includes(status.name))
          this.getLeadsByStatus(currentStatus, 1)
          return
        }

        await this.$apollo.queries.leadsPipelineStatuses.refetch()
        this.getLeadsByStatus(this.leadsPipelineStatuses, 1)
      }, 1000)
    },

    async invitationSent () {
      this.selectedLeadIds = []
      this.landingpageSlugFilter = ''
      await this.refetchLeads({})
    },

    assignUserColors (users) {
      users.forEach(section => {
        section.leads.forEach(lead => {
          const assignedUser = lead.assignedUser
          if (assignedUser) {
            const userId = assignedUser.id

            // Assign a consistent color based on user ID
            assignedUser.color = this.getRandomColor(userId)
          }
        })
      })

      return users
    },

    getRandomColor (id) {
      let hash = 0
      for (let i = 0; i < id.length; i++) {
        hash = id.charCodeAt(i) + ((hash << 5) - hash)
      }
      let color = '#'
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF
        color += ('00' + value.toString(16)).substr(-2)
      }
      return color
    },

    async getLeadsByStatus (statuses, page) {
      const leadsByStatus = []
      this.leadsByStatusLoading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: LEADS,
          variables: {
            landingpageSlug: this.landingpageSlugFilter,
            input: {
              companyId: this.$auth.user.companyId,
              onlyAssignedToMe: this.isOnlyAssignedToMe,
              sortBy: this.sortBy,
              view: 'platform',
              sortDesc: this.sortDesc,
              page: page,
              itemsPerPage: this.itemsPerPage,
              search: this.search,
              filters: { ...this.filters, currentStatus: statuses.map(status => status.name) }
            }
          }
        })

        if (!data) { return }

        for (const status of statuses) {
          if (status.name === Status.CREATED) {
            const leadWithStatusNew = leadsByStatus.find(lead => lead.status === Status.NEW)
            if (leadWithStatusNew) {
              const sortedLeads = this.sortLeadsByPosition([...leadWithStatusNew.leads, ...data.leads.entries.filter(lead => lead.currentStatus === Status.CREATED)])
              const updatedLeadObject = {
                ...leadWithStatusNew,
                leads: sortedLeads,
                total: leadWithStatusNew.total + data.leads.totalByStatus.find(item => item.status === Status.CREATED)?.total
              }

              // Replace the object in the parent array to trigger reactivity
              const index = leadsByStatus.findIndex(lead => lead.status === Status.NEW)
              leadsByStatus.splice(index, 1, updatedLeadObject)
            } else {
              leadsByStatus.push({
                id: status.id,
                // Note: even though the status is CREATED, the title comes from NEW.
                title: leadStatus[Status.NEW],
                leads: data.leads.entries.filter(lead => lead.currentStatus === Status.CREATED),
                total: data.leads.totalByStatus.find(item => item.status === Status.CREATED)?.total ?? 0,
                status: Status.CREATED,
                page,
                position: status.position,
                isLast: status.isLast ?? false,
                isFirst: status.isFirst ?? false,
                isEditable: false
              })
            }
          } else {
            leadsByStatus.push({
              id: status.id,
              title: leadStatus[status.name] ? leadStatus[status.name] : status.name,
              leads: data.leads.entries.filter(lead => lead.currentStatus === status.name),
              total: data.leads.totalByStatus.find(item => item.status === status.name)?.total ?? 0,
              status: status.name,
              page,
              position: status.position,
              isLast: status.isLast ?? false,
              isFirst: status.isFirst ?? false,
              isEditable: status.name !== Status.ORDER_RECEIVED && status.name !== Status.REACHED && status.name !== Status.NEW
            })
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      } finally {
        this.leadsByStatusLoading = false
        this.leadsByStatus = this.assignUserColors(leadsByStatus)
      }
    },

    usersForSelect () {
      if (!this.users || this.users.length === 0) {
        return []
      }
      return this.users.map(user => ({
        value: user.id,
        text: `${user.firstname} ${user.lastname}`,
        firstName: user.firstname,
        lastName: user.lastname,
        color: this.getRandomColor(user.id)
      }))
        .sort(sortByString('text'))
    },

    sortLeadsByPosition (leads) {
      return leads.sort((a, b) => {
        if (a.position === null) return 1
        if (b.position === null) return -1
        return a.position - b.position
      })
    },

    async deleteLeads () {
      this.isDeletingLeads = true

      if (this.selectedLeadIds.length) {
        try {
          await this.$apollo.mutate({
            mutation: DELETE_LEADS,
            variables: {
              input: {
                leadIds: this.selectedLeadIds
              }
            }
          })
          this.selectedLeadIds = []
          this.triggerResetDeleteLeads = true
          await this.refetchLeads({})
          this.$tracking.event('Leads', 'Clicked', 'Delete Leads')
          bus.$emit(eventNames.TRIGGER_ANIMATION)
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.delete-leads.success') })
        } catch (error) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error') })
          // eslint-disable-next-line no-console
          console.log(error)
        } finally {
          this.isDeletingLeads = false
        }
      }
    },

    async updateLeadsPosition (leadIds) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_LEADS_POSITION,
          variables: {
            leadIds: leadIds
          }
        })
        this.$tracking.event('Leads', 'Clicked', 'Update Leads Position')
        this.triggerResetAssignLeads = true
      } catch (e) {
        showSnackbarMessage('error', this.$t('alerts.lead-position.error'))
      }
    },

    async statusDeleted () {
      await this.refetchLeads({})
    },

    async assignLeads (userId) {
      this.isAssigningLeads = true

      if (this.selectedLeadIds.length) {
        try {
          await this.$apollo.mutate({
            mutation: ASSIGN_LEADS,
            variables: {
              input: {
                userId,
                leadIds: this.selectedLeadIds
              }
            }
          })
          this.selectedLeadIds = []
          this.triggerResetAssignLeads = true
          await this.refetchLeads({})
          this.$tracking.event('Leads', 'Clicked', 'Assign Leads')
          bus.$emit(eventNames.TRIGGER_ANIMATION)
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.assign-leads.success') })
        } catch (error) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.assign-leads.error') })
        } finally {
          this.isAssigningLeads = false
        }
      }
    },

    selectLead (items = []) {
      this.selectedLeadIds = []
      items.forEach(item => {
        if (!item.invitationBlockedReason) {
          this.selectedLeadIds.push(item.id)
        }
      })
      if (this.selectAllLeadsState === 'selected') this.selectAllLeadsState = 'idle'
    },

    async allItemsSelected ({ isSelectAll, slug }) {
      this.selectAllLeadsState = 'loading'

      if (isSelectAll) {
        try {
          if (slug === 'invitation') {
            const { data: { getAcquisitionBoosterLeadIds } } = await this.$apollo.mutate({
              mutation: ACQUISITION_BOOSTER_LEAD_IDS,
              variables: {
                input: {
                  companyId: this.$auth.user.companyId,
                  onlyAssignedToMe: this.isOnlyAssignedToMe,
                  search: this.search,
                  landingpageSlug: this.landingpageSlugFilter,
                  filters: Object.assign({}, this.filters)
                }
              }

            })
            this.selectedLeadIds = getAcquisitionBoosterLeadIds.leadIds
          } else if (['deleteLeads', 'assignLeads'].includes(slug)) {
            const { data: { leadIds } } = await this.$apollo.mutate({
              mutation: LEAD_IDS,
              variables: {
                input: {
                  companyId: this.$auth.user.companyId,
                  onlyAssignedToMe: this.isOnlyAssignedToMe,
                  search: this.search,
                  filters: Object.assign({}, this.filters)
                }
              }
            })
            this.selectedLeadIds = leadIds.leadIds
          }
          this.selectAllLeadsState = 'selected'
        } catch (err) {
          this.selectAllLeadsState = 'idle'
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      } else {
        this.selectedLeadIds = []
        this.selectAllLeadsState = 'idle'
      }
    },

    async updateFetchIdsParameters ({ contactFilter, landingpageSlug, filters = {}, activeTool }) {
      if (activeTool === 'invitation') this.updateInvitationParameters({ contactFilter, landingpageSlug, filters })
      if (activeTool === 'deleteLeads') this.updateDeleteLeadsParameter({ contactFilter, filters })
    },

    async updateInvitationParameters ({ contactFilter, landingpageSlug, filters = {} }) {
      if (!contactFilter || !landingpageSlug) return
      this.landingpageSlugFilter = landingpageSlug
      if (contactFilter === InvitationContactFilter.CUSTOM) {
        this.selectedLeadIds = []
      } else {
        try {
          const { data: { getAcquisitionBoosterLeadIds } } = await this.$apollo.mutate({
            mutation: ACQUISITION_BOOSTER_LEAD_IDS,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                landingpageSlug,
                filters: Object.assign({}, filters)
              }
            }
          })
          this.$tracking.event('Leads', 'Clicked', 'Invitation Parameters')
          this.selectedLeadIds = getAcquisitionBoosterLeadIds.leadIds
        } catch (err) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      }
    },

    async updateDeleteLeadsParameter ({ contactFilter, filters = {} }) {
      if (!contactFilter) return
      if (contactFilter === DeleteLeadsContactFilter.CUSTOM) {
        this.selectedLeadIds = []
      } else {
        try {
          const { data: { getDeleteLeadsLeadIds } } = await this.$apollo.mutate({
            mutation: LEAD_IDS,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                filters: Object.assign({}, filters)
              }
            }
          })
          this.$tracking.event('Leads', 'Clicked', 'Delete Parameters')
          this.selectedLeadIds = getDeleteLeadsLeadIds.leadIds
        } catch (err) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      }
    }
  },
  beforeUnmount () {
    clearTimeout(this._timerId)
  }
}
</script>
