const status = {
  created: 'labels.lead-status.created',
  new: 'labels.lead-status.new',
  followUp: 'labels.lead-status.follow-up',
  unreached: 'labels.lead-status.unreached',
  unreachedAtAll: 'labels.lead-status.unreached-at-all',
  noFurtherContact: 'labels.lead-status.no-further-contact',
  reached: 'labels.lead-status.reached',
  appointmentOnSite: 'labels.lead-status.appointment-on-site',
  orderReceived: 'labels.lead-status.order-received'
}

export const defaultSystemStatuses = ['created', 'new', 'followUp', 'unreached', 'unreachedAtAll', 'noFurtherContact', 'reached', 'appointmentOnSite', 'orderReceived']

export const statusForSelect = Object.entries(status).map(([value, text]) => ({ value, text }))

export default status
