<template>
  <div :style="cssProps">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <div
          class="pointer"
          v-on="on"
          @click="onclick"
        >
          <v-icon
            :class="{ 'due-date-red': isDayOrLessBeforeDueDate(item.dueDate) }"
            class="due-date-calendar"
            :size="size"
          >
            mdi-calendar-month
          </v-icon>
          <template v-if="item.dueDate">
            <span
              class="due-date"
              :class="[{ 'due-date-red': isDayOrLessBeforeDueDate(item.dueDate) }, customClass]"
            >
              {{ formatDate(item.dueDate, 'dateShort') }}
            </span>
            <v-icon
              size="14"
              class="pencil"
            >
              mdi-pencil
            </v-icon>
          </template>
        </div>
      </template>
      <span class="due-date-tooltip">{{ $t('lead.card.tooltip.due-date') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import leadsDueDateMixin from '@/modules/leads/LeadList/Cards/mixins/leadsDueDateMixin'

export default {
  mixins: [leadsDueDateMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: '16'
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary
      }
    }
  },
  methods: {
    onclick ($event) {
      $event.stopPropagation()
      this.$emit('click')
    }
  }

}
</script>

<style scoped>
.due-date-calendar:hover, .due-date:hover, .due-date-calendar:focus, .pencil:hover, .pencil:focus {
  color: var(--primary-color);
}

.due-date-tooltip {
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.due-date {
  color: #9E9E9E;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.due-date-red {
  color: #E41D1D;
}

.pencil, .pointer {
  cursor: pointer;
}

@media (min-width: 960px) {
  .pencil {
    display: none;
  }
}
</style>
