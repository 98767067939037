<template>
  <div>
    <BaseHeader
      :title="$t('lead.title')"
      :style-variant="16"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          {{ $t('lead.subtitle') }}
        </p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>
    <v-row v-if="lead">
      <v-col
        cols="12"
        md="4"
      >
        <base-info
          :lead="lead"
          @lead-updated="refetchLead"
        />
        <mail-info
          v-if="!isHoppermationActivated"
          :lead="lead"
          @unsubscribed="refetchLead"
        />
        <Tags
          :lead="lead"
        />

        <Conversions
          v-if="isHoppermationFeatureActive"
          :lead="lead"
        />

        <Permission permission="lead:invite">
          <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
            <Invitation
              class="mt-4"
              :lead="lead"
              :on-refetch="refetchLead"
            />
          </Feature>
        </Permission>

        <status
          :lead-id="lead.id"
          :status="lead.currentStatus"
          :assigned-user-id="lead.assignedUser && lead.assignedUser.id"
          @assigned-user-changed="refetchLead"
        />

        <Permission permission="lead:write">
          <commentary :lead-id="lead.id" />
        </Permission>

        <Permission permission="lead:export">
          <template v-if="hasExport">
            <v-card
              outlined
              class="mt-4"
            >
              <div class="pt-12 pb-2 pl-4 primary">
                <span class="title white--text">
                  {{ $t('lead.export.title') }}
                </span>
              </div>
              <div class="pa-4">
                <Feature :feature-slug="featureNames.LEAD_EXPORT">
                  <div>
                    <p class="text-subtitle-2 ma-0 pa-0">
                      {{ $t('lead.export.body-texts.0') }}
                    </p>
                    <div class="grey--text text--darken-1 caption">
                      {{ $t('lead.export.body-texts.1') }}: {{ lead.exportedAt ? formatDate(lead.exportedAt) : $t('labels.never') }}
                    </div>
                    <div class="justify-end mt-2 d-flex">
                      <v-btn
                        small
                        color="primary"
                        @click.prevent="downloadLead"
                      >
                        {{ $t('buttons.lead.export.export-csv') }}
                      </v-btn>
                    </div>
                  </div>
                </Feature>
                <Feature :feature-slug="featureNames.CRM">
                  <Crm
                    :class="{'mt-4':isFeatureActive(featureNames.LEAD_EXPORT)}"
                    :lead="lead"
                  />
                </Feature>
              </div>
            </v-card>
          </template>
        </Permission>

        <Permission permission="lead:delete">
          <Feature :feature-slug="featureNames.DELETE_CONTACT">
            <DeleteContactContainer
              :lead="lead"
            />
          </Feature>
        </Permission>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <lead-property-map
          v-if="hasMap"
          :lead="lead"
        />
        <recommendation :status="lead.currentStatus" />
        <timeline :lead="lead" />
      </v-col>
    </v-row>
    <v-alert
      v-if="error"
      type="error"
    >
      <span v-if="error === 'NOT_FOUND'">
        {{ $t('alerts.lead.error-no-lead') }}
      </span>
      <span v-else>
        {{ $t('alerts.lead.error') }} ({{ error }}).
      </span>
    </v-alert>
  </div>
</template>

<script>
import LEAD from './queries/Lead.gql'
import BaseInfo from './BaseInfo'
import MailInfo from './MailInfo'
import Timeline from './Timeline'
import Recommendation from './Recommendation'
import Status from './Status'
import Commentary from './Commentary'
import LeadPropertyMap from './LeadPropertyMap'
import { exportLead } from '@/lib/export'
import Invitation from './Invitation'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from './HeaderDesign.vue'
import featureMixin from '@/mixins/feature'
import Conversions from './Conversions.vue'
import DeleteContactContainer from './DeleteContactContainer'
import Crm from './Crm'
import Tags from './Tags'
import MAIL_PROVIDER from '../queries/MailProvider.gql'
import Permission from '@/components/Permission'

export default {
  components: {
    BaseInfo,
    MailInfo,
    Timeline,
    Status,
    Commentary,
    LeadPropertyMap,
    Recommendation,
    Invitation,
    BaseHeader,
    HeaderDesign,
    Conversions,
    DeleteContactContainer,
    Crm,
    Tags,
    Permission
  },
  mixins: [featureMixin],
  apollo: {
    mailProvider: {
      query: MAIL_PROVIDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: (data) => data.company.mailProvider
    },
    lead: {
      query: LEAD,
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      pollInterval: 60000,
      error (error) {
        this.error = error.graphQLErrors[0]?.extensions?.code
      }
    }
  },
  data () {
    return {
      lead: null,
      error: null
    }
  },
  computed: {
    isHoppermationFeatureActive () {
      return this.isFeatureActive(this.featureNames.HOPPERMATION)
    },
    isHoppermationActivated () {
      return this.mailProvider === 'HOPPERMATION'
    },
    breadcrumbItems () {
      return [
        { text: this.$t('leads.title'), to: { path: `/leads${this.$route?.hash || ''}` }, disabled: false, exact: true },
        { text: 'Details' }
      ]
    },
    hasMap () {
      const hasValuation = this.lead.valuations.length > 0
      const hasPlotValuation = this.lead.conversions.some(conversion => conversion.type === 'PLOT_VALUATION')
      const hasPropertyRequest = this.lead.conversions.some(conversion => conversion.type === 'PROPERTY_REQUEST')

      return hasValuation || hasPlotValuation || hasPropertyRequest
    },
    hasExport () {
      return this.isFeatureActive(this.featureNames.LEAD_EXPORT) || this.isFeatureActive(this.featureNames.CRM)
    }
  },
  methods: {
    async downloadLead () {
      await exportLead({ id: this.lead.id })
      await this.$apollo.queries.lead.refetch()
    },
    async refetchLead () {
      await this.$apollo.queries.lead.refetch()
    }
  }
}
</script>
