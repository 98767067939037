import { showSnackbarMessage } from '@/lib/snackbarMessages'
import SET_LEAD_DUE_DATE from '@/modules/leads/LeadList/Cards/queries/SetLeadDueDate.gql'
import DELETE_LEAD_DUE_DATE from '@/modules/leads/LeadList/Cards/queries/DeleteLeadDueDate.gql'
import * as Sentry from '@sentry/vue'

export default {
  methods: {
    async setDueDate ({ lead, date }) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SET_LEAD_DUE_DATE,
          variables: {
            input: { leadId: lead.id, date: new Date(date).toISOString() }
          }
        })

        if (data.setDueDate) {
          const { id, dueDate } = data.setDueDate

          if (lead.id === id) {
            this.$set(lead, 'dueDate', dueDate)

            this.$tracking.event('Leads', 'Clicked', 'Due Date Added')
            showSnackbarMessage('success', this.$t('alerts.lead.due-date.add.success'))
          }
        }
      } catch (error) {
        Sentry.captureException(error)
        showSnackbarMessage('error', this.$t('alerts.lead.due-date.add.error'))
      }
    },
    async deleteDueDate ({ lead }) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: DELETE_LEAD_DUE_DATE,
          variables: {
            input: { leadId: lead.id }
          }
        })

        if (data.deleteDueDate) {
          const { id } = data.deleteDueDate
          if (lead.id === id) {
            this.$set(lead, 'dueDate', undefined)

            this.$tracking.event('Leads', 'Clicked', 'Due Date Deleted')
            showSnackbarMessage('success', this.$t('alerts.lead.due-date.delete.success'))
          }
        }
      } catch (error) {
        Sentry.captureException(error)
        showSnackbarMessage('error', this.$t('alerts.lead.due-date.delete.error'))
      }
    },

    isDayOrLessBeforeDueDate (date) {
      if (!date) {
        return false
      }

      const now = new Date()
      now.setHours(0, 0, 0, 0)

      const dueDate = new Date(date)
      dueDate.setHours(0, 0, 0, 0)

      // Adjust the due date if it falls in Monday and Sunday
      // Both dates are adjusted to Friday
      const adjustedDate = (date) => {
        const dueDate = new Date(date)

        switch (dueDate.getDay()) {
          case 1: dueDate.setDate(date.getDate() - 3); break // Monday -> Friday
          case 0: dueDate.setDate(date.getDate() - 2); break // Sunday -> Friday
          default: dueDate.setDate(date.getDate() - 1); break
        }

        return dueDate
      }

      const adjustedDueDate = adjustedDate(dueDate)
      return adjustedDueDate <= now
    }
  }
}
