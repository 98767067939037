<template>
  <v-card
    class="date-picker-container"
    :class="customClass"
  >
    <v-date-picker
      v-model="dueDateLocal"
      :locale="$auth.user.locale"
      class="date-picker-content"
      width="100%"
      :min="new Date().toISOString().slice(0, 10)"
    />
    <v-card-actions>
      <v-icon
        v-if="dueDate"
        size="18"
        color="red"
        @click="deleteDueDate"
      >
        mdi-trash-can-outline
      </v-icon>
      <v-spacer />
      <v-btn
        text
        @click="closeDueDate"
      >
        <span class="close-button">{{ $t('buttons.due-date-calendar.close') }}</span>
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="saveDueDate"
      >
        <span class="save-button">{{ $t('buttons.due-date-calendar.save') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    dueDate: {
      type: String,
      default: null
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dueDateLocal: this.dueDate
        ? new Date(this.dueDate).toISOString().slice(0, 10)
        : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
    }
  },
  watch: {
    dueDate (newDate) {
      this.dueDateLocal = newDate
    }
  },
  methods: {
    deleteDueDate () {
      this.$emit('delete')
      this.closeDueDate()
    },
    saveDueDate () {
      this.$emit('save', this.dueDateLocal)
      this.closeDueDate()
    },
    closeDueDate () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.date-picker-container {
  z-index: 9;
  box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.20) !important;
}

::v-deep .v-date-picker-table {
  height: unset;
}

.save-button, .close-button {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.close-button {
  color: #9E9E9E;
}

.v-card {
  border-radius: 0 !important;
}

.lead-card-calendar {
  position: absolute;
  width: 100%;
  top: 60px;
}

.base-info-calendar {
  width: 300px;
}

@media (max-width: 960px) {
  .lead-card-calendar {
    top: -60px;
  }
  .base-info-calendar {
    width: 100%;
  }
}
</style>
